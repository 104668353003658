$popoverTargetMargin: 6px; // distance to popover pip from target
$popoverPipSize: 5px;
$popoverPaddingH: 16px;
$popoverPaddingV: 8px;

.popoverContainer {
  position: relative;
}

.popoverWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + #{$popoverPipSize} + #{$popoverTargetMargin});
  height: 0;
}

.popover {
  font-size: 1rem;
  background: #fff;
  border-radius: $globalRadius;
  padding: $popoverPaddingV $popoverPaddingH;
  color: $tone6;

  &:not(.active) {
    transform: scale(.85);
    opacity: 0;
    pointer-events: none;
  }

  filter: drop-shadow(0 1.5px 2px rgba(0,0,0,.3));
  transform-origin: 50% calc(0% - #{$popoverPipSize + $popoverTargetMargin});
  transition-property: transform opacity;
  transition-duration: .15s;

  >*:first-child {
    border-top-left-radius: $globalRadius;
    border-top-right-radius: $globalRadius;
    overflow: hidden;
  }

  >*:last-child {
    border-bottom-left-radius: $globalRadius;
    border-bottom-right-radius: $globalRadius;
    overflow: hidden;
  }

  &__title {
    font-size: .85em;
    margin: (-$popoverPaddingV) (-$popoverPaddingH) $popoverPaddingV;
    background: linear-gradient(to bottom, #fff, $tone1);
    border-bottom: 1px solid $tone2;
    line-height: 32px;
    white-space: nowrap;
    padding: 0 $popoverPaddingH;
    color: $tone4
  }

  ul {
    font-size: .9em;
    margin: (-$popoverPaddingV) (-$popoverPaddingH) (-$popoverPaddingV);
    list-style: none;
    padding: 4px 0;
    overflow: hidden;

    a {
      display: block;
      padding: 8px 24px;
      white-space: nowrap;
      color: $brandPrimary;
      font-weight: 400;

      &:hover, &:focus {
        background: $brandLighter;
        color: #fff;
      }

      &:focus { outline: 0; }
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: -$popoverPipSize;
    left: 50%;
    margin-left: -$popoverPipSize;
    width: 0;
    border-bottom: $popoverPipSize solid #fff;
    border-right: $popoverPipSize solid transparent;
    border-left: $popoverPipSize solid transparent;
  }
}

.popoverTrigger {
  display: inline-block;
  &:hover { cursor: pointer; }
  &.popoverActive:focus { outline: 0; }
}
