.formSelector {
  &__wrapper {
    position: relative;
    display: inline-block;
  }

  &__input {}

  &__dropdown {
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    z-index: 50;
    box-shadow: 0 2px 4px rgba(0,0,0,.05);
  }

  &__dropdownItem {
    line-height: 40px;
    padding-left: 14px;
    padding-right: 14px;

    &--selected {
      color: $brandLighter;
      font-weight: bold;
    }

    &--highlighted {
      background: $brandLighter;
      color: #fff;
    }

    &--custom {
      font-style: italic;
    }
  }
}
