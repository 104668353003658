.formField {
  display: flex;
  @media (--small-only) { flex-wrap: wrap };
  align-items: center;
  &:not(:first-child) { margin-top: 12px; }

  &--textarea { display: block; }
  &--center { justify-content: center; }

  &__labelContainer {
    @media (--small-only) {
      flex: 1 0 100%;
      margin-bottom: 8px;
    };

    @media (--medium-up) {
      width: 180px;
      max-width: 100%;
    }
  }

  &__label { white-space: nowrap; }

  &__required {
    font-size: .8em;
    font-style: italic;
    text-transform: lowercase;
    color: $tone3;
    margin: 0;
    margin-top: .2em;

    @media (--small-only) {
      margin-left: 8px;
      display: inline;
    };
  }

  &__inputContainer {
    display: flex;
    align-items: center;

    .statusPip {
      margin-left: -24px;
    }
  }

  &__input {
    padding-right: 32px;
  }

  &__statusContainer {
    margin-left: 12px;
  }
}

.fieldErrors {
  margin-left: 12px;

  font-size: .9em;
  color: #fff;
  background: $stateAlert;

  border-radius: $globalRadius;

  padding: 6px 10px;


  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    white-space: nowrap;
    margin: 0;

    &:not(:last-child) { margin-bottom: 4px; }
  }
}
