$gridGutterWidth: 8px;

// Large sizes
$secondaryColumnWidth: 250px;
$primaryColumnWidth: calc(100% - #{$secondaryColumnWidth} - #{$gridGutterWidth*2});

.grid {
  display: flex;
  flex-wrap: wrap;
  @media (--large-up) { flex-wrap: nowrap; }

  margin-left: -$gridGutterWidth/2;
  margin-right: -$gridGutterWidth/2;

  margin-top: -8px;
}

  .primaryColumn, .secondaryColumn {
    margin: 0 $gridGutterWidth/2;
    margin-top: 8px;
  }

  .primaryColumn {
    min-width: 350px; // NOTE: arbitrary
    max-width: 100%; // NOTE: arbitrary
    flex-basis: $primaryColumnWidth;
    flex-grow: 2;
    flex-shrink: 1;

    @media (--large-up) { flex-shrink: 0; }
  }

  .secondaryColumn {
    flex-basis: $secondaryColumnWidth;
    flex-grow: 1;
    flex-shrink: 2;

    @media (--large-up) {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
