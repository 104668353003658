/* Define the "system" font family */
@font-face {
  font-family: "systemFallback";
  font-style: normal;
  font-weight: 300;
  src:
    local(".SFNSText-Light"),
    local(".HelveticaNeueDeskInterface-Light"),
    local(".LucidaGrandeUI"),
    local("Ubuntu Light"),
    local("Segoe UI Light"),
    local("Roboto-Light"),
    local("DroidSans"),
    local("Tahoma");
}

$fontStack: system, -apple-system, BlinkMacSystemFont, "systemFallback";

body {
  font-family: $fontStack;
  color: $tone6;
}

p {
  line-height: 1.4;
  margin: 0;

  &:not(:last-child) { margin-bottom: 16px; }
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

// Override normalize.css opinionated rules
input, textarea, select, optgroup, button {
  font-family: inherit;
  line-height: 1;
}

.address {
  >* { display: block; }
}

a {
  text-decoration: none;
  color: $brandAccent;

  &:hover { cursor: pointer; }
}

ul { padding: 0; }

.list {
  margin: 0;
  padding: 0;

  &--noBullet { list-style: none; }

  >li:not(:first-child) { margin-top: 8px; }
}

.rte {
  line-height: 1.4;
  white-space: pre-wrap;
}

.systemId {
  font-family: menlo, monospace;
}

.hint {
  font-size: .95em;
  font-style: italic;
  color: $brandPrimary;
}
