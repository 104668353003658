.avatar {
  display: inline-flex; // places border inside image to maintain external dims
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid $tone2;
  flex-shrink: 0; // prevent ovalising if parent using flexbox
  background: #fff;

  img { display: block; }

  &--updatable {
    position: relative;
    user-select: none;

    // &:not(:hover):not(.popoverActive)::after { display: none; }
    //
    // &::after {
    //   content: "Update";
    //   font-weight: 600;
    //   font-size: .8rem;
    //   text-transform: uppercase;
    //   letter-spacing: .05em;
    //   text-shadow: 0 1px 1px rgba(0,0,0,.2);
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   color: $tone2;
    //   background: change-color($tone6, $alpha: 0.3);
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   border-radius: 50%;;
    // }
    //
  }

  &.updating {
    opacity: .5;
  }

  &:focus, &.popoverActive {
    outline: 0;
    border-color: $focusColour;
    box-shadow: 0 0 0 2px $focusColour;
  }
}
