$brandPrimary: #3c1757;

$brandLighter: #573380;
$brandAccent: #E50982;

$tone1: #FAF8FA;
$tone2: #F0EBEF;
$tone3: #B9B2B6;
$tone4: #73666D;

$tone6: #35242E;

// Focus

$focusColour: change-color($brandAccent, $lightness: 75%);

// State Colours

$stateInfoTint: change-color($brandAccent, $lightness: 98.5%);

$stateSuccess: #00d483;
$stateSuccessTint: change-color($stateSuccess, $lightness: 98.5%);

$stateWarning: #FFBB00;
$stateWarningTint: change-color($stateWarning, $lightness: 98.5%);

$stateAlert: #F1124C;
$stateAlertTint: change-color($stateAlert, $lightness: 98.5%);

// Base Component Colours

$sidebarBg: $brandPrimary;
$pageBg: linear-gradient(to bottom, $tone1, $tone2 80px) no-repeat $tone2;

$cardBg: #fff;
