.doNotResuscitateMessage {
  @extend .chip;
  margin-top: -4px 0;
  display: inline;
}

// HACK overrides .editableSection styles
.medicalConditionItem {
  &:not(.editableSection--editing) { display: flex; }

  .editableSection__title {
    margin-right: .35em;
    margin-bottom: 0;
  }
}
