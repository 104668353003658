.checkboxGroup {
  // margin-top: 16px;
  &__item {
    display: flex;
    align-items: center;
    & + & { margin-top: 8px; }
  }

  &__checkbox { margin-right: 8px; }
}
