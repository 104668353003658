html {
  box-sizing: border-box;
  background: $pageBg;
  overflow-y: scroll; // prevent scroll bar jump
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

%pageWidthContainer {
  margin: 0 auto;
  max-width: $largeBreakpoint - $sidebarWidth;
  padding-left: $mainContentContainerPadding/2;
  padding-right: $mainContentContainerPadding/2;

  @media (--medium-up) {
    padding-left: $mainContentContainerPadding;
    padding-right: $mainContentContainerPadding;
  }
}

#pageContainer {
  width: 100%;
  @media (--sidebarVisible) {
    padding-left: $sidebarWidth;
  }
  @media print {
    padding-left: 0;
  }
}

#mainContentContainer {
  @extend %pageWidthContainer;
  padding-bottom: $mainContentContainerPadding;
}

*:focus {
  outline-color: $focusColour;
  outline-width: 3px;
}
*:focus:active {
  outline: none;
}
