.careVisitsFeed {
  &__pollingIndicator {
    opacity: 1;
    transition: opacity .3s ease;

    &--hidden { opacity: 0; }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 32px;
      border-bottom: 1px solid $tone2;
    }
  }

  &__sectionHeading {
    font-size: .83rem;
    font-weight: 500;
    margin: 8px 16px;
    color: $tone4;
    text-align: left;
  }

  .careVisitListItem {
    $careVisitListItem: &;

    .cardListItem__inner {
      justify-content: flex-start !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &--compact {
      .cardListItem__inner {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .careVisitListItem__suRoutineCont {
        line-height: 1.3;
      }

      .careVisitListItem__carerName {
        font-size: .85rem;
      }
    }

    &--inProgress {

    }

    &--success {
    }

    &--actionRequired {
      color: $brandAccent;
      animation: flash .3s ease alternate;
      animation-delay: 1s;
      animation-iteration-count: 4;
      animation-fill-mode: both;
    }

    &--resolved {

    }

    &__suRoutineCont {
      margin-left: 8px;
      line-height: 1.5;
      @media (--medium-up) { min-width: 250px; }
    }

    &__timeCont {
      flex-grow: 1;
    }

    &__startDate {
      // font-weight: 700;
    }

    &__duration {
      // background: $tone3;
      // border-radius: 3px;
      // padding: 2px 4px;
      margin-top: 4px;
      // width: 110px;
      // text-align: center;

      &--inProgress {
      //   background: $focusColour;
        .careVisitListItem__durationText {
          animation: durationUpdate .5s;
          &:after {
            content: "…";
            animation: ellipsisFade 10s ease infinite;
          }
        }
      }
    }

    &__durationText {
      // color: #fff;
      font-size: .8rem;
      font-weight: 500;
      color: $tone4;

      @keyframes durationUpdate {
        from { opacity: 0; }
        to { opacity: 1; }
      }
      @keyframes ellipsisFade {
        0% { opacity: 1; }
        10% { opacity: 0; }
        20% { opacity: 1; }
        100% { opacity: 1; }
      }
    }

    &__serviceUserName {
      font-weight: 500;
    }

    &__routineTitle {
      font-size: .9em;
      color: $tone4;
    }

    @keyframes flash {
      from {
        background-color: #fff;
        border-top-color: $tone2;
      }
      to {
        background-color: $stateAlertTint;
        border-top-color: $stateAlert;
      }
    }
  }
}
