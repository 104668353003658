.addServiceUserCard {
  animation: enter .4s ease;

  @keyframes enter {
    from {
      opacity: .3;
      transform: translateY(-75px);
    }
  }
}
