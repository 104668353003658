.formSection {
  &__header {
    &:hover { cursor: pointer; }
    &+* { margin-top: 12px; }
  }

  &__sectionTitle {
    margin: 0;
    font-size: 1.25em;
    line-height: 32px;
    font-weight: 400;
    color: $tone4;
  }

  &__nav {
    margin-top: 0;
  }
}
