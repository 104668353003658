.editableSection {
  $editableSection: &;

  position: relative;
  overflow: hidden;

  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid transparent;
  border-color: $tone2;

  .actionBar { background: 0 }

  &__title {
    margin-bottom: 12px;
    color: $tone4;
    font-size: .95rem;
    letter-spacing: .02em;
  }

  & + & { margin-top: 4px; }

  transition: background .2s ease, border-color .2s ease, box-shadow .2s ease;

  &--editing {
    box-shadow: 0 1px 2px $tone1;
    border-color: $tone2;
  }

  &:focus { outline: 0; }

  &__statusBlock {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    height: 20px;
  }

  &__statusText {
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: 600;
    letter-spacing: 0.02em;
    margin-right: 6px;
    position: relative;
    top: .1em;
    animation: statusText 1s ease;

    &--unsavedChanges {
      color: $tone4;
    }

    &--success {
      color: $stateSuccess;
      animation: statusTextFade 3s ease;
    }
  }

  @keyframes statusText {
    0% {
      transform: translateX(50%);
      opacity: 0;
    }
    20% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes statusTextFade {
    0% {
      transform: translateX(50%);
      opacity: 0;
    }
    20% {
      transform: translateX(0);
      opacity: 1;
    }
    80% {opacity: 1;}
    100% {opacity: 0;}
  }

  &__editIcon svg { transition: fill .3s ease; }

  &:not(&--editing) {
    &:hover, &:active {
      background: $tone1;
      cursor: pointer;
    }

    &:focus, &:focus-within { border-color: $focusColour; }

    &:not(:hover) #{$editableSection}__editIcon svg { fill: $tone3; }
  }
}

.editableSectionAddButton {
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px dashed darken($tone2, 5%);
  padding: 16px 16px;
  color: $tone4;
  font-weight: 500;

  transition: background .2s ease;

  &:hover, &:active { background: $tone1; }
  &:focus:not(:hover):not(:active) {
    border-style: solid;
    border-color: transparent !important;
  }
}
