// $cardTintBg: change-color($tone1, $lightness: 97%);
$cardTintBg: $tone1;
$cardTintBorderColor: scale-color($cardTintBg, $lightness: -2.5%);
$cardHeaderBg: linear-gradient(
  to top,
  $cardTintBg,
  change-color($cardTintBg, $lightness: 99%)
);

.card {
  $card: &;

  font-size: 15px;
  background: $cardBg;
  border-radius: $cardRadius;
  border: 1px solid #fff;
  box-shadow: 0 1px 1px 0.5px change-color($tone6, $alpha: 0.08);

  // overflow: hidden;

  // &:not(:first-child) { margin-top: 10px; }
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &__header {
    background: $cardHeaderBg;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $cardHeaderHeight;
    padding-left: $cardPaddingHorizontal;
    padding-right: $cardPaddingHorizontal;
    font-weight: 500;
    font-size: 1.05em;

    &--center {
      text-align: center;
    }
    &--secondary {
      height: 44px;
      #{$card}__title {
        color: $tone4;
        font-weight: 400;
        font-size: 0.95em;
      }
    }
  }

  &__title {
    font: inherit; // inherit from header
    margin: 0;

    &:last-child {
      flex: 1;
    } // allow titles with no action items to be centered
  }

  &__actionItems {
    display: flex;
    align-items: center;

    > * {
      margin-left: 16px;
    }

    .button {
      padding: 0 8px;
      height: 32px;
      .iconFill {
        fill: $brandLighter;
      }
    }
  }

  &__section {
    background: #fff;
    padding: $cardPaddingVertical $cardPaddingHorizontal;

    > :first-child {
      margin-top: 0;
    }

    &--abut {
      padding-top: 0;
    }

    &--center {
      text-align: center;

      #{$card}__subSection {
        justify-content: center;
      }
      #{$card}__subSectionTitle {
        margin-right: 0.7em !important;
      }
    }

    &--collapse {
      // &:not(:first-child) { border-top: 0; }
      padding: 0 $cardPaddingVertical/2;
      &:last-child {
        padding-bottom: $cardPaddingVertical/2;
      }
    }

    &--part {
      &:not(:first-child) {
        border-top: 1px solid $cardTintBorderColor;
      }
    }

    &--tint {
      background: $cardTintBg;

      &:not(:first-child) {
        border-top: 1px solid $cardTintBorderColor;
      }

      #{$card}__header + & {
        border-top: 0;
      }
    }
  }

  &__sectionTitle {
    color: $tone4;
    font-size: 0.85em;
    margin: 0;
    margin-bottom: 2px;
    font-weight: 400;
  }

  &__subSection {
    @media (--medium-up) {
      display: flex;
    }

    .card__subSectionTitle {
      font-size: 0.9em;
      font-weight: 500;
      color: $tone4;

      @media (--small-only) {
        margin-bottom: 8px;
      }

      @media (--medium-up) {
        min-width: 30%;
        margin-right: 16px;
      }
    }

    #{$card}__subSectionContent {
      font-size: 0.9em;
      font-weight: 500;
      p {
        margin: 0;
      }
    }

    &--stack {
      display: block;

      #{$card}__subSectionTitle {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }

    &--secondary {
      #{$card}__subSectionTitle {
        font-weight: 400;
        margin-bottom: 4px;
      }
    }
  }

  &__subSection + &__subSection {
    margin-top: 16px;
  }

  .cardListItem {
    padding: 0;

    a {
      color: inherit;
      display: block;
      &:active {
        background-color: lighten($tone1, 0.9);
      }
    }

    &__inner {
      padding: $cardPaddingVertical $cardPaddingHorizontal;
      min-height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > *:not(:last-child) {
        margin-right: 16px;
      }

      &--arrow::after {
        content: '>';
        color: $tone3;
      }
    }

    &__title {
      font-weight: 500;
      margin: 0;
      flex-grow: 1;
    }

    &--inactive {
      a {
        background: $tone1;
        color: $tone4;
      }
    }
  }
}
