.recentCareVisitsCard {
}

.recentCareVisitsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  height: 32px;

  background: #fff;
  border-radius: $globalRadius;
  box-shadow: 0 1px 1px rgba(0,0,0,.07);

  font-size: .9em;
  color: $tone4;
  text-decoration: none;

  p { margin: 0; }

  & + & { margin-top: 4px; }

  &__date { flex: 1; }

  &__time { color: $tone3; }

  &:active { box-shadow: 0 1px 1px rgba(0,0,0,.2); }

}
