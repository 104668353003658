.callout {
  text-align: center;
  max-width: 550px;

  margin-left: auto;
  margin-right: auto;

  color: $brandPrimary;

  &--expand {
    max-width: none;
  }

  &--success {}
  &--error {}
  &--warning {}

  &--info {
    background-color: $stateInfoTint;
    border-color: change-color($brandAccent, $lightness: 85%);
  }

  &--alert {
    background-color: $brandAccent;
    border-color: #fff;
    color: #fff;
  }

  >.card__header {
    background: 0;
    border: 0;
    height: 36px;
    padding-top: 16px;
  }

  >.card__section {
    background: 0;
    padding: 16px 24px;
  }
}
