.alertBarContainer {
  position: fixed;
  bottom: 16px;
  right: 16px;

  z-index: 5000;
}

.alertBar {
  position: relative;
  padding: 16px 16px;
  font-size: .95rem;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);

  background: $tone2;
  color: $brandPrimary;

  min-width: 250px;
  @media (--medium-up) { min-width: 350px; }

  transform-origin: center right;
  animation-name: alertBarEnter;
  animation-duration: .5s;
  animation-timing-function: ease;

  &:not(:last-child) { margin-bottom: 4px; }

  &__title { margin: 0; }

  &__hide {
    position: absolute;
    top: 8px;
    right: 8px;
    display: block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 1.3rem;
    color: rgba(0,0,0,.4);
    cursor: pointer;
  }

  &--info {
    background-color: $stateInfoTint;
    color: $brandLighter;
  }

  &--success {
    background-color: $stateSuccessTint;
    color: $stateSuccess;
  }

  &--error {
    background-color: $stateAlertTint;
    color: $stateAlert;
  }
  &--warning {
    background-color: $stateWarningTint;
    color: $stateWarning;
  }

  @keyframes alertBarEnter {
    from {
      opacity: 0;
      transform: translateX(100%) scale(.5);
    }
  }
}
