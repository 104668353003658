$tabBorderColor: change-color($brandPrimary, $lightness: 85%, $saturation: 15%);
$tabInactiveBorderColor: $tone2;

.tabbedCardSection {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    background: $tone1;
    border-bottom: 1px solid $tabBorderColor;
    padding-top: 8px;
  }

  .card__header + & &__header {
    padding-top: 0;
  }
  &__tabList {
    display: flex;
    margin: 0;
    list-style: none;
  }

  // .card__actionItems {
  // }

  &__tab {
    $tab: &;
    border: 1px solid transparent;
    border-bottom: 0;
    padding: 12px 16px 11px;
    margin-right: 4px;

    font-weight: 500;
    color: $tone4;

    border-top-left-radius: $globalRadius;
    border-top-right-radius: $globalRadius;

    &:not(#{$tab}--active) {
      cursor: pointer;
    }

    &:focus:not(:active) {
      outline: 0;
      &:not(#{$tab}--active) {
        border-color: $focusColour;
      }
    }

    &:active:not(#{$tab}--active) {
      border-color: scale-color($tabBorderColor, $lightness: 30%);
    }

    &--active {
      border-color: $tabBorderColor;
      background: #fff;
      color: $brandLighter;
      margin-bottom: -1px;
    }
  }
}
