.icon {
  >* {;
    display: block;
    width: 100%;
    height: 100%;

    // iOS 10 fixes
    max-width: 100%;
    max-height: 100%;

    // NOTE Android browser doesn't respect % max-height and requires static value
  }
}
