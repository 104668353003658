.chip {
  $chipBg: $focusColour;

  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background: $chipBg;
  color: #fff;
  border-radius: $globalRadius;

  border: 1px solid scale-color($chipBg, $lightness: -5%);
  border-top: 1px solid scale-color($chipBg, $lightness: 10%);

  margin-top: 2px;
  margin-bottom: 2px;
  &:not(:last-child) { margin-right: 4px; }
}

.chipStrip {
  display: flex;
  align-items: center;
  // padding: 8px 0 8px 16px;
  // border: 1px solid $tone2;
  // background: #fff;
  margin-top: 4px;

  border-radius: $globalRadius;

  &__heading {
    white-space: nowrap;
    margin: 0;
    margin-right: 16px;
    color: $tone4;
  }

  &__chips {
    display: flex;
    overflow: scroll;

    .chip { white-space: nowrap; }
  }
}
