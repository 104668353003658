.statusPip {
  $pip-size: 12px;
  width: $pip-size;
  height: $pip-size;
  border-radius: 9999px;

  background: $tone2;
  transition: background-color .3s ease;

  &--valid { background-color: $stateSuccess; transform: none;}
  &--invalid { background-color: $stateAlert; transform: none;}
}
