$sidebarWidth: 224px;
$navBarHeight: 60px;

$mediumBreakpoint: 768px;
$largeBreakpoint: 1080px;

$globalRadius: 3px;

$mainContentContainerPadding: 16px;

$cardPaddingHorizontal: 16px;
$cardPaddingVertical: 12px;
$cardRadius: $globalRadius;
$cardHeaderHeight: 48px;

$minContentWidth: $mediumBreakpoint;

@custom-media --small-only (width < #{$mediumBreakpoint});
@custom-media --medium-only (#{$mediumBreakpoint} <= width < #{$largeBreakpoint});
@custom-media --large-only (width >= #{$largeBreakpoint});

@custom-media --medium-up (width >= #{$mediumBreakpoint});
@custom-media --large-up (width >= #{$largeBreakpoint});

// Sidebar queries
@custom-media --sidebarVisible (width >= #{$minContentWidth + $sidebarWidth});
// @custom-media --sidebarHidden (width < #{$minContentWidth + $sidebarWidth});

