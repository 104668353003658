.titleBar {
  border-bottom: 1px solid change-color($tone6, $alpha: .06);
  margin-left: -$mainContentContainerPadding;
  margin-right: -$mainContentContainerPadding;
  padding:  32px $mainContentContainerPadding 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__headingGroup { position: relative; }

  &__title {
    margin: 0;
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 36px;
  }


  &__parentLink, &__title {
    animation: titleEnter .5s ease;
    animation-fill-mode: both;
  }

  &__parentLink {
    position: absolute;

    top: -10px;
    display: block;
    color: $tone4;
    font-size: .7em;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
    white-space: nowrap;

    & + .titleBar__title {
      animation-delay: .2s;
      animation-duration: .4s;
    }
  }

  @keyframes titleEnter {
    from {
      opacity: 0;
      transform: translateX(-7px);
    }
  }

  @media print {
    display: block;
    padding: 0 0 8px;
    margin: 0 0 8px;

    &__actionGroup { display: none; }
    &__parentLink { display: none; }
    &__title { font-size: 1rem; }
  }
}
