.textareaField {
  &__labelContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .statusPip { margin-left: 12px; }
  }

  &__required {
    font-size: .8em;
    font-style: italic;
    text-transform: lowercase;
    color: $tone3;
    margin: .2em 0 0 8px !important; // p:last-child override
    // margin-top: .2em;
    // margin-left: 8px;
  }

  &:not(:first-child) { margin-top: 16px; }
}
