.sidebar {
  width: $sidebarWidth;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: $sidebarBg;
  box-shadow: inset -1px 0 1px scale-color($sidebarBg, $lightness: -1%);
  padding: 24px 0;

  display: flex;
  flex-direction: column;

  @media print {
    display: none;
  }

  &__userBlockWrapper {
    display: flex;
    justify-content: center;
    padding: 16px;
    text-align: center;
  }

  &__lower {
    margin-top: auto;
  }

  .userBlock {
    @extend %primaryNavUserBlock;

    &__info {
      margin-top: 12px;
      font-size: 15px;
    }

    &__userName {
      justify-content: center;
    }
  }

  .careCompanyBlock {
    margin: 0 16px;

    &__logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      opacity: .3;
    }
  }
}

.sidebarNav {
  margin: 12px 0;
  ul {
    list-style: none;
    padding: 0;
  }

  &__item {
    line-height: 52px;
    display: block;
    padding: 0 36px;
    font-weight: 400;
    letter-spacing: 0.01em;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;

    & {
      color: $tone2;
    }
    &.active {
      color: #fff;
      background: scale-color($sidebarBg, $lightness: +8%, $saturation: -10%);
      border-color: scale-color($sidebarBg, $lightness: +10%);
      box-shadow: -1px 1px 1px scale-color($sidebarBg, $lightness: -2%);
    }
  }
}
