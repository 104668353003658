.buttonGroup {
  $buttonGroupPadding: 2px;
  display: flex;

  &.dirty {
    .buttonGroup__button {
      &:not(.buttonGroup__button--active) { color: $tone3; }
    }
  }

  &__button {
    min-width: 52px;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: $globalRadius;
    @include button-color(transparent, $tone4);

    &--active, &--active:disabled {
      @include button-color($brandLighter, #fff);
      animation: activeEnter .2s ease;
    }

    // &:not(:last-child) { margin-right: 4px; } // TODO conditionally use $buttonGroupPadding if larger
    margin-right: 4px;
  }

  @keyframes activeEnter {
    from { transform: scale(1.04); }
  }

}

// TODO rename button group
.buttonGroup {
  // >.button + .button { margin-left: 8px; }
  // >.button:not(:first-child) { margin-left: 8px; }
}
