.assessmentQuestion {
  margin-left: -17px;
  margin-right: -17px;
  @extend .card__section;

  padding-top: 16px;
  padding-bottom: 16px;

  color: $tone4;

  border-top: 1px solid change-color($tone1, $lightness: 96%);

  &.pristine:first-child,
  &:not(.pristine) + &.pristine {
    $highlightBackground: change-color($brandPrimary, $lightness: 98%, $saturation: 50%);
    $highlightBorder: scale-color($highlightBackground, $lightness: -5%);

    color: $brandPrimary;
    background: $highlightBackground;
    border-color: $highlightBorder;
  }

  // &.pristine ~ &.pristine { background: inherit; }

  &__container {
    display: flex;
    flex-direction: column;
    @media (--medium-up) { flex-direction: row; }

    justify-content: space-between;
    align-items: center;
  }

  &__question {
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    min-width: 300px;
    flex: 1;
    width: 100%;
    margin-bottom: 16px;
    text-align: center;

    @media (--medium-up) {
      flex-basis: 400px;
      margin-right: 16px;
      margin-bottom: 0;
      text-align: left;
    }
  }

  .buttonGroup {
    &__button {
      order: 2;
      &.safeAnswer {
        order: 1;
      }
    }

    &__button--active {
      &:not(.safeAnswer) { @include button-color($brandAccent, #fff); }

      &.safeAnswer { @include button-color($stateSuccess, #fff); }
      //   @include button-color(
      //     $stateSuccessTint,
      //     $stateSuccess,
      //     change-color($stateSuccess, $saturation: 70%, $lightness: 80%)
      //   );
    }

    &:not(.dirty) .safeAnswer { border-color: change-color($tone2, $lightness: 90%); }
  }

  &__answerContainer {
    display: flex;
    align-items: stretch;

    .formField { margin-bottom: 0; }
  }

  &__commentButton {
    margin-left: 8px;
    width: 30px;
    line-height: 28px;
    align-self: center;
    padding: 0;
    border-color: transparent;
  }

  &__commentContainer {
    margin-top: 12px;
    &.pristine { animation: commentContainerEnter .2s ease both; }
  }

  @keyframes commentContainerEnter {
    from {
      transform: translateY(20px);
      opacity: .3;
    }
  }

  // completed assessment
  &__comment {
    white-space: pre-wrap;
    font-style: italic;
    color: $tone4;
    font-size: .95em;
    margin: 0;
  }

  &__answer {
    margin: 0;
  }
}
