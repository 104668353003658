@mixin button-color($bgColor, $fontColor, $borderColor: null) {
  $bgGradientColor: scale-color($bgColor, $lightness: -3%);

  @if $borderColor == null { $borderColor: $bgGradientColor; }

  color: $fontColor;
  background: linear-gradient(to bottom, $bgColor, $bgGradientColor);
  border-color: $borderColor;
  text-shadow: .5px .5px 1px rgba(0,0,0,.05);

  &:not(:disabled):active {
    background: scale-color($bgColor, $lightness: 3%);
  }
}

// button reset
button {
  border: 0;
  font-size: 1rem;
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  line-height: inherit;
}

a.button { display: block; }

.button {
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  padding: 8px 24px;
  text-align: center;

  &:focus { outline: 0; }
  &:focus:not(:active) {
    z-index: 5; // prevent box-shadow being overlapped by adjacent objects
    box-shadow: 0 0 0 2px $focusColour;
    border-color: $focusColour;
  }

  &.primary {
    @include button-color($brandLighter, #fff);
  }

  &.accent {
    @include button-color($brandAccent, #fff);
  }

  &.secondary {
    @include button-color(#fff, $brandPrimary, darken($tone2, 7%));
  }

  &.tertiary {
    @include button-color($tone2, $brandLighter);
  }

  &.textOnly {
    border: 0;
    border-radius: $globalRadius;
    letter-spacing: .035em;
    font-size: .9em;
    font-weight: 600;
    text-transform: uppercase;
  }

  &:not(:disabled):not(.disabled) { cursor: pointer; }

  &:disabled, &.disabled {
    @include button-color($tone2, $tone3);
    text-shadow: none;

    .iconFill { fill: $tone3; }
  }

  &--expand { width: 100%; }

  &--small {
    font-size: .875rem;
    font-weight: 500;
    border-radius: 3px;
  }

  >.icon {
    // position: static;
  }

}

.navGroup {
  display: flex;
  align-items: center;
  >*:not(:first-child) { margin-left: 8px; }

  &--center { justify-content: center; }

  &--stack {
    display: block;
    >*:not(:first-child) {
      margin-left: 0;
      margin-top: 3px;
    }
  }
}
