.card.signatureCard {
  display: inline-block;
  border-color: $tone2;

  .navGroup { margin: 0 !important; }
}

.signatureContainer {
  position: relative;
  background: #fff;
  user-select: none;

  &::before {
    content: "Please sign here";
    color: $tone3;
    font-weight: 200;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 48px/2);
    line-height: 48px;
    padding-bottom: 24px;
    font-size: 2rem;
    letter-spacing: .03em;
    pointer-events: none;
    transition: color .2s ease, opacity .3s ease;
    border-bottom: 1px dashed $tone3;
  }
  &:active, &:not(.empty) {
    &::before { color: transparent; }
  }

  &:not(.empty)::before { opacity: 0; }
}

.signatureCanvas {
  display: block;
  position: relative; // push z-index
}
