.profileCard {
  text-align: center;

  &__avatar {
    margin-left: auto;
    margin-right: auto;
  }

  &__name {
    font-size: 1.05em;
    margin: 16px 0 4px;
    font-weight: 500;
    color: $brandPrimary;
  }

  &__age {
    color: $tone4;
    font-size: .9em;
  }

  &__systemId {
    margin: 0;
    font-weight: 600;
    font-size: 1.15em;
    letter-spacing: 0.02em;
  }

  &__status {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &__statusButton {
    &--draft { color: $tone4; }
    &--active { color: $stateSuccess; }
    &--archived { color: $tone4; }
  }
}
