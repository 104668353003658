.serviceUsersList {
  list-style: none;
  margin-top: 8px;
  padding: 0;

  box-shadow: 0 1px 1px rgba(0,0,0,.07);
  border-radius: 3px;
}

.serviceUserLI {
  // &--draft { animation: serviceUserLIEnter .5s ease; }
  & { animation: serviceUserLIEnter .5s ease; }

  a {
    background: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 0 16px;
    border-top: 1px solid #F5F1F3; // TODO replace with variable
    text-decoration: none;
    color: inherit;

    &:active { background-color: $tone1; }
  }

  &:first-child a {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-top: 0;
  }

  &:last-child a {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__name {
    font-weight: 500;
    text-decoration: none;
    color: $brandPrimary;
    margin-right: auto;
  }

  &__status {
    width: 60px;
    text-align: center;
    margin-right: 24px;
    font-size: .8em;
    color: $tone3;
  }

  &__systemId {
    font-weight: 500;
    font-size: .9em;
    width: 130px;
    text-align: right;
  }

  @keyframes serviceUserLIEnter {
    from {
      opacity: 0;
    }
  }
}
