.assessmentView{
  @extend .card;

  padding: 8px;
  @media (--medium-up) { padding: 24px 48px 48px; }
  @media print { padding: 0; }

  &__title {
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 1.5em;
    line-height: 1.4;
    color: $brandPrimary;

    .serviceUserName {
      font-weight: 400;
      font-size: .95em;
    }
  }

  .assessmentSection {
    margin-top: 48px;

    &__title {
      font-size: 1.2em;
      padding: 0 16px;
      margin: 0 -16px 16px;
    }
  }

  .assessmentField {

    &__answer { font-weight: 500; }

    &.safeAnswer {}

    &.alertAnswer {
      .assessmentField__answer { color: $brandAccent; }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }

    &:nth-child(odd) {
      background: $tone1;
      margin: 0 -12px 0;
      padding: 12px;
      margin-top: 0;
      border-radius: 2px;
    }

    &.plainTextBlock .assessmentField__container {
      flex-direction: column;
    }

    &__label {
      color: $tone4;
      margin-bottom: 0;
      margin-right: .8em;
      max-width: 65%;
      flex-grow: 1;
    }

    &__comment {
      @extend %assessmentCommentary;
      text-align: right;
      margin-left: auto;
    }

    & + .assessmentField { margin-top: 12px; }

    &.plainTextBlock .assessmentField__answer { @extend %assessmentCommentary; }
  }

}

%assessmentCommentary {
  margin-top: 4px;
  font-style: italic;
  line-height: 1.5;
  max-width: 600px;
}

.assessmentDetailsCard {
  border-color: $tone2;
  box-shadow: none;
}

.assessmentSignatory {
  &__name {
    display: block;
    margin-top: 16px;
    font-style: italic;
  }
}
