// NOTE shared between sidebar and navbar
%primaryNavUserBlock {
  color: $tone2;

  .userBlock {
    &__userName {
      font-weight: 500;
      font-size: 1em;
      display: flex;
      align-items: center;
      @media (--small-only) {
        display: none;
      }

      .icon {
        margin-left: 0.3em;
        margin-top: -0.1em;
      }
    }

    &__avatar {
    }

    &__accountName {
      margin-top: 2px;
      color: $tone3;
      font-size: 0.9em;
      @media (--small-only) {
        display: none;
      }
    }
  }
}

.navBar {
  background: $brandPrimary;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 5000;

  @media print {
    display: none;
  }

  &__container {
    @extend %pageWidthContainer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    display: flex;
    list-style: none;
    margin: 0;
  }

  &__listItem {
    a {
      line-height: 50px;
      color: inherit;
      display: block;
      padding: 0 16px;
      white-space: nowrap;

      &.active {
        background: $brandLighter;
      }
    }
  }

  .userBlock {
    @extend %primaryNavUserBlock;
    display: flex;
    align-items: center;

    &__info {
      margin-left: 8px;
      font-size: 14px;
    }

    &__userName {
      justify-content: flex-start;
    }
  }
}
