.careVisitStatusIcon {
  $iconSize: 14px;
  border-radius: 9999px;
  height: $iconSize;
  width: $iconSize;
  margin-right: 10px;
  border: 1px solid transparent;
  &--inProgress { position: static; border: 0; }
  &--successful { background: $stateSuccess; border-color: darken($stateSuccess, 2%); }
  &--partiallySuccessful { background: $stateWarning; border-color: darken($stateWarning, 2%); }
  &--attentionRequired { background: $stateAlert; border-color: darken($stateAlert, 2%); }

  &--reviewed {
    background: linear-gradient(to right, $stateSuccess 50%, $stateAlert 50%);
    border-color: darken($stateSuccess, 2%);
  }
}
