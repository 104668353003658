.visitUsersCard {
  a { color: $brandPrimary; }

  &__serviceUser {
    text-align: center;
    .avatar {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__user {
    > a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar { margin-right: 16px; }

  }
}
