$modalZIndex: 5000;

.modalContainer {

  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (--sidebarVisible) { padding-left: $sidebarWidth; }

  // overlay
  &::after {
    content: "";

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: $modalZIndex - 1;

    background: change-color($tone6, $alpha: .2);
    animation: modalContainerEnter .2s ease;
  }
}

.modal {
  @media (--medium-up) { margin: 24px; }
  box-shadow: 1px 2px 2px rgba(0,0,0,.1);
  animation: modalEnter .15s ease;

  z-index: $modalZIndex;
}

@keyframes modalContainerEnter {
  from { background: transparent; }
}

@keyframes modalEnter {
  from {
    transform: scale(.95) translateY(10px);
    opacity: 0;
  }
}
