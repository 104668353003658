// Reset platform input styles
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 296px;
}

input,
textarea {
  display: block;
  border-radius: 4px;
  border: 1px dashed darken($tone2, 5%);
  background: lighten($tone1, 1%);
  padding: 10px 12px;
  font-size: 16px; // NOTE: important to prevent zoom on iOS when focusing
  @media (--large-up) {
    font-size: 15px;
  }
  line-height: 18px;
  color: $tone6;
  font-weight: 500;

  &.dirty {
    border-style: solid;
  }

  &:focus {
    outline: 0;
    background-color: #fff;
    border-style: solid;
    border-color: $focusColour;
    box-shadow: 0 0 0 1px $focusColour;
  }

  &::placeholder {
    color: $tone3;
    font-weight: 400;
  }

  &:disabled {
    color: $tone3;
  }
}

textarea {
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  @media (--large-up) {
    max-width: 650px;
  }
}

.inputFileHidden {
  position: absolute;
  visibility: hidden;
  max-width: 0 !important;
  // left: 0;
  right: 0;
}

label {
  color: $tone4;
  font-size: 0.9rem;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;

  // padding-left: 16px;
  // border-left: 2px solid $tone2;
  margin-top: 36px;
}

legend {
  height: 0;
  position: relative;
  top: -1.8em;
  // left: -18px;
  padding: 0;
  font-weight: 500;
  color: $brandPrimary;
  font-size: 0.95em;
}

.actionBar {
  margin: ($cardPaddingVertical + 8px) (-$cardPaddingHorizontal)
    (-$cardPaddingVertical);
  padding: 16px;
  background: linear-gradient(
    to bottom,
    change-color($tone2, $alpha: 0.5),
    $tone1 3px,
    scale-color($tone1, $lightness: 10%) 64px
  );

  &--collapse {
    margin: 0;
  }
  &--compact {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .navGroup {
    justify-content: center;
    @media (--medium-up) {
      justify-content: flex-end;
    }
  }
}

.radio {
  &--inline {
    display: flex;
    align-items: center;
    input {
      margin-right: 0.5em;
    }
  }

  & + & {
    margin-top: 4px;
  }
}
