.carePlanCard {
  &__itemsSection {}

  &__auditSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__version {
    font-size: .95em;
    color: $tone3;
  }
}

.carePlanItemBlocks {
  display: flex;
  flex-flow: row wrap;
  padding: 0 4px 8px;
}

.carePlanItemBlock {
  $itemSpacing: 8px;
  display: block;
  padding: 12px 20px;
  color: inherit;
  border-radius: $globalRadius;
  background: #fff;
  box-shadow: 0 .5px 1px rgba(0,0,0,.2);

  margin: $itemSpacing/2;

  flex: 1 1 100%;
  @media (--medium-up) { flex-basis: calc(50% - #{$itemSpacing}); }
  min-height: 72px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__upper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $brandLighter;

    .icon {
      flex: 0 0 auto;
      margin-right: .95em;
      position: static;
    }
  }

  &__note {
    color: $tone4;
    font-size: .8em;
  }



  .iconFill { fill: $brandLighter; }

  &--empty {
    box-shadow: none;
    background: 0;
    border: 1px dashed change-color($tone3, $lightness: 50%);
    color: change-color($tone3, $lightness: 50%);
  }

  &__title {
    font-size: .95rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.25;
  }
}

.carePlanCardItem {

}

.auditBadge {
  $badge: &;
  color: $tone4;

  &--signedOff {
    #{$badge}__status {}
  }

  &__status {
    margin: 0;
    font-size: .9rem;
    font-weight: 500;
  }

  &__details {
    font-size: .85em;
    margin: .1em 0 0;
  }
}
