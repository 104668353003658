.completionCallout {
  max-width: none;

  &__list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: -4px;
  }

  >.card__header {
    font-size: .95em;
    color: $brandLighter;
  }
}

.completionItem {
  flex: 1 0 160px;
  box-shadow: 0 0 1px 0 $tone3;

  & { margin: 4px !important; } // override inherited :last-child rule

  &__title { font-size: .9em; }

  &__status {
    text-transform: uppercase;
    font-size: .8em;
    font-weight: 500;
    letter-spacing: .04em;
    color: $tone3;

    &--complete {
      color: $stateSuccess;
      text-shadow: 0 0 8px $stateSuccessTint;
    }
  }
}
